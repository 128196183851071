import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout/Layout"
import Seo from "../components/SEO"
import MailSub from "../components/MailSub"
import { Stack } from "@mui/material"

const About = ({ data }) => {
  const image = getImage(data.hero)
  const imageSupport = getImage(data.support)

  return (
    <Layout>
      <Seo
        title="About"
        desc="Learn more about Vietnamese Guide and how it can help you with your Vietnamese studying."
      />
      <div className="half hero">
        <div className="left-half">
          <h2>About</h2>
          <div>
            <p>
              Vietnamese Guide is a free online language resource for Vietnamese
              learners.
            </p>
            <p>
              Learning Vietnamese isn't easy, so be patient and let's learn
              together.
            </p>
          </div>
          <MailSub styleClass="hide" desktop />
        </div>
        <div className="desktop-only">
          <GatsbyImage image={image} alt="Vietnamese Guide" />
        </div>
      </div>
      <Stack spacing={2} sx={{ p: "var(--s3)", textAlign: "center" }}>
        <h2>Our community</h2>
        <p>
          Vietnamese Guide could not exist without the amazing support from our
          viewers. Thank you for studying with us, for listening to our podcast
          every week, and for supporting us on{" "}
          <strong>
            <a
              href="https://www.patreon.com/vietnameseguide"
              target="_blank"
              rel="noopener noreferrer"
            >
              Patreon
            </a>
          </strong>
          /{" "}
          <strong>
            <a
              href="https://www.buymeacoffee.com/vietnameseguide"
              target="_blank"
              rel="noopener noreferrer"
            >
              Buymeacoffee
            </a>
          </strong>
          .
        </p>
        <p>You are amazing!</p>
        <p>
          Unfortunately, in-person meetups aren’t possible these days, but we
          occasionally host online events with our members.
        </p>
        <p>
          If you'd like to book private Vietnamese lessons, please{" "}
          <Link to="/contact">
            <span style={{ textDecoration: "underline" }}>contact us</span>
          </Link>
          .
        </p>
        <p style={{ marginBottom: "1.5rem" }}>Happy learning, Ha & the team.</p>
        <a
          href="https://www.buymeacoffee.com/vietnameseguide"
          target="_blank"
          rel="noopener noreferrer"
        >
          <GatsbyImage image={imageSupport} alt="Buy me a coffee" />
        </a>
      </Stack>
    </Layout>
  )
}

export default About

export const query = graphql`
  query Banner {
    hero: file(relativePath: { eq: "camera-girl.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500)
      }
    }
    support: file(relativePath: { eq: "red-button.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200)
      }
    }
  }
`
